<template>
  <div class="zone">
    <div class="indent">
      <div class="left">发货单档案</div>
      <div class="right">
        <!-- <el-button type="primary" size="small">搜索</el-button> -->
      </div>
    </div>
    <div class="conronone">
      <div class="record">
        <div class="recordleft">
          发货单号:{{
            detailData.dispatch_code
          }}
        </div>
        <div class="recordright"  v-if="detailData.shipping_status">
          <!-- <div class="yuan"></div> -->
          {{ detailData.shipping_status }}
        </div>
      </div>
      <div class="particulars">
        <div class="particularsleft">
          <div class="particularsleftone">订单编号:</div>
          <div class="particularslefttwo">{{ detailData.order_code }}</div>
        </div>
        <div class="particularscentre">
          <div class="particularscentreone">经销商:</div>
          <div class="particularscentretwo">{{ detailData.customer_name }}</div>
        </div>
        <div class="particularscentre">
          <div class="particularscentreone">发货时间:</div>
          <div class="particularscentretwo">{{ detailData.delivery_time }}</div>
        </div>
        <div class="particularsright">
          <div class="particularsrightone">创建时间</div>
          <div class="particularsrighttwo">{{ detailData.time_stamp }}</div>
        </div>
      </div>
    </div>
    <div class="commodity">
      <div class="commodityLeft">商品信息</div> 
      <div class="excleBtn" v-if="detailData.file_url" @click="exportExcle(detailData.file_url)">导出物品UDI</div> 
    </div>
    <div class="conron">
      <div class="sheet">
        <el-table :data="tableDataDity" stripe style="width: 100% height:100%">
          <el-table-column label="商品图片" align="center">
            <!-- <template slot-scope="scope">
              <image :url="scope.row.image" width="80" height="80" />
            </template> -->
            <template slot-scope="{ row }">
              <img  :src="row.image ? row.image : require('@/assets/img/no_pict.png')" alt="" width="60" height="60" />
            </template>
          </el-table-column>
          <el-table-column
            prop="inventory_name"
            label="商品名称"
            height="30"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="batch"
            label="批号"
            height="30"
            align="center"
          ></el-table-column>
          <el-table-column
            label="数量"
            align="center"
          >
            <template slot-scope="scope">
              {{scope.row.orders_num}}{{scope.row.standard.split('/')[1]}}
            </template>
          </el-table-column>
          <el-table-column
            prop="price"
            label="价格"
            align="center"
          >
            <template slot-scope="scope">
              {{ $tool.moneyFormatter(Number(scope.row.price), 2) }}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="logistics">
      <div class="logisticsLeft">物流信息</div>
    </div>
    <div class="material">
      <div class="trackingmax" v-if="errmsg == ''">
        <div class="tracking" v-if="logisticsData">
          <div class="trackingNumber">
            <div class="trackingNumberleft">
              物流单号:{{
                detailData.shipping_code
              }}
            </div>
          </div>
          <div class="companylogistics">
            <div class="companylogisticsone">
              <div>物流公司:</div>
            </div>
            <div class="companylogisticstwo">
              <div>
                {{ detailData.shipping_type }}
              </div>
            </div>
          </div>
          <div class="companylogistics">
            <div class="companylogisticsone">
              <div>物流类型:</div>
            </div>
            <div class="companylogisticstwo">
              <div>
                {{ detailData.shipping_method }}
              </div>
            </div>
          </div>
          <div class="companylogistics">
            <div class="companylogisticsone">
              <div>收货人:</div>
            </div>
            <div class="companylogisticstwo">
              <div>
               {{detailData.receive_man}}
              </div>
            </div>
          </div>
          <div class="companylogistics">
            <div class="companylogisticsone">
              <div>电话:</div>
            </div>
            <div class="companylogisticstwo">
              <div>
                {{ detailData.receive_mobile }}
              </div>
            </div>
          </div>
          <div class="companylogistics">
            <div class="companylogisticsone">
              <div>收货地址:</div>
            </div>
            <div class="companylogisticstwo">
              <div>
                {{ detailData.receive_address }}
              </div>
            </div>
          </div>
        </div>
        <div class="trackingmiss"></div>
        <div class="materials">
          <ul class="layui-timeline">
            <li
              class="layui-timeline-item"
              v-for="(item, index) in information"
              :key="index"
            >
              <div class="time-first">
                {{ item.ftime ? item.ftime : item.upTime }}
              </div>
              <i class="layui-timeline-axis circle">
                <img src="@/assets/img/transit.png" alt="" v-if="index == 0" />
              </i>
              <div class="layui-timeline-content layui-text">
                <h3 :class="index == 0 ? 'layui-timeline-title' : ''">
                  {{ item.status ? item.status : item.city }}
                </h3>
                <p>{{ item.context ? item.context : item.address }}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <p class="errmsg" v-else>{{ errmsg }}</p>
    </div>

    <div
      id="main"
      v-if="shipping_method === '冷链'&&desc ==''"
      v-loading="loadingechart"
    >
    </div>
    <div v-if="shipping_method === '冷链'&&desc !=''" class="descCla"> {{desc}}</div>

  </div>
</template>

<script>
import { getshippingtype } from '@/api/search.js'

const wlgs = {
  1: '美乐维',
  2: '顺丰速运',
  3: '中铁快运',
  4: 'EMS',
  5: '华空物流',
}
const fhzt = {
  0: '其他',
  1: '代发货',
  2: '部分发货',
  3: '部分完成',
  4: '已完成',
}
import apiDetail from '@/api/searchDetail.js'
export default {
  name: 'DispatchsDetaisl',
  data() {
    return {
      reverse: false,
      tableDataDity: [],
      detailData: {},
      wlgs,
      fhzt,
      information: [],
      logisticsData: {},
      shipping_method: '', //物流类型: 快递，冷链
      errmsg: '',
      desc: '',
      loadingechart: true, //加载统计图
    }
  },
  mounted() {},
  created() {
    this.detailId = this.$route.query.id
    this.gitdispatchDetail()
  },
  methods: {
    gitdispatchDetail() {
      apiDetail.dispatchDetail({ dispatch_id: this.detailId }).then((res) => {
        this.detailData = res.data
        this.tableDataDity = res.data.shopping_data
        this.courier_company = res.data.courier_company
        this.shipping_method = res.data.shipping_method
        let obj = {}
        if (this.shipping_method === '冷链') {
          obj = {
            shipping_type: 1, //物流类型，是否冷链，0：否，1，是
            dispatch_id: this.detailId,
            shipping_code: res.data.shipping_code,
          }
        } else {
          obj = {
            shipping_type: 0, //物流类型，是否冷链，0：否，1，是
            dispatch_id: this.detailId,
            shipping_code: res.data.shipping_code,
            com: this.courier_company,
          }
        }
        this.getshippingData(obj)
      })
    },
    getshippingData(obj) {
      getshippingtype(obj).then(
        (res) => {
          this.desc = res.data.desc;

          if (obj.shipping_type == 1) {
            this.loadingechart = true
            this.information = res.data.dev_state_list
            this.echartsShow(res.data.dev_temp_list)
          } else {
            this.information = res.data.express_information
          }
          this.logisticsData = res.data;
          this.errmsg = res.data.desc ? res.data.desc : ''

        },
        (err) => {
          console.log(err, '---')
        },
      )
    },
    // 导出excle
    exportExcle(file) {
      var a = document.createElement('a')
      var event = new MouseEvent('click')
      a.download = 'fileName'
      a.href = file
      a.dispatchEvent(event)
    },
    // 温度折线图
    echartsShow(data) {
      var myChart = this.$echarts.init(document.getElementById('main'))
      //配置图表
      var option = {
        title: {
          text: '冷链运输温度图',
          left: '5%',
          top: '2%',
          textStyle: {
            fontSize: 12,
          },
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
          },
        },
        grid: {
          show: true,
        },
        legend: {
          right: '5%',
          top: '2%',
          data: ['温度'],
        },
        xAxis: {
          type: 'category',
          data: data.time_info,
          splitLine: {
            show: true,
          },
          boundaryGap: false,
          lineStyle: {
            //轴线样式设置
            color: 'rgb(215, 215, 215)',
            width: 1,
            type: 'solid',
          },
          axisLine: {
            show: true, //显示和隐藏轴线
            lineStyle: {
              //轴线样式设置
              color: 'rgba(215, 215, 215, 1)',
              width: 1,
              type: 'solid',
            },
          },
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '{value} °C',
          },
          axisLine: {
            show: true, //显示和隐藏轴线
            lineStyle: {
              //轴线样式设置
              color: 'rgba(215, 215, 215, 1)',
              width: 1,
              type: 'solid',
            },
          },
        },
        series: [
          {
            showSymbol: false,
            name: '温度',
            data: data.temp_info,
            type: 'line',
            color: 'rgba(249, 158, 155, 1)',
          },
        ],
      }
      myChart.setOption(option)
      this.loadingechart = false
    },
  },
}
</script>

<style lang="scss" scoped>
.indent {
  height: px(46);
  background-color: transparent;
  display: flex;
  margin-bottom: px(5);
}
.left {
  justify-content: flex-start;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #111111;
}

.right {
  flex: 1;
  justify-content: flex-end;
  display: flex;
  align-items: center;
}
.commodity {
  height: px(46);
  background-color: transparent;
  display: flex;
  margin-bottom: px(5);
  .excleBtn{
    font-weight: bold;
    color: #2d354c;
    // line-height: px(56);
    height: px(35);
    line-height: px(35);
    margin-top: px(10);
    margin-left: px(30);
    padding: 0 px(10);
    cursor: pointer ;
    background: #67C23A;
    border-radius: 5px;
    color: #fff;
  }
}
.commodityLeft {
  font-weight: bold;
  color: #2d354c;
  line-height: px(56);
}
.logistics {
  height: px(46);
  background-color: transparent;
  display: flex;
  margin-bottom: px(5);
}
.logisticsLeft {
  font-weight: bold;
  color: #2d354c;
  line-height: px(56);
}
.Calendar {
  margin: 0 px(4);
  width: px(300);
}
.conron {
  overflow: auto;
}

.conronone {
  background-color: #fff;
  border-radius: 5px;
  padding: 0 px(8);
}
.record {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ebf0f6;
}
.recordleft {
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #111111;
  padding:8px 6px;
}
.recordright {
  display: flex;
  align-items: center;
  margin-right: 14px;
}
.yuan {
  margin-right: px(6);
  width: px(5);
  height: px(5);
  border-radius: 50%;
  background: #000;
}
.sheet {
  border-radius: px(5);
  padding: px(5);
}
.particulars {
  display: flex;
}
.particularscentre {
  flex: 1;
  padding: px(10) 0;
  margin-left: px(15);
  border-right: 1px solid #ebf0f6;
}
.particularsleft {
  flex: 1;
  padding: px(10) 0;
  border-right: 1px solid #ebf0f6;
}
.particularsleftone {
  color: #999999;
}
.particularslefttwo {
  font-weight: 500;
  color: #333333;
}
.particularsright {
  flex: 1;
  padding: px(10) 0;
  margin-left: px(15);
}
.particularscentreone {
  color: #999999;
}
.particularscentretwo {
  color: #333333;
}
.particularsrightone {
  color: #999999;
}
.particularsrighttwo {
  font-weight: 500;
  color: #333333;
}
.material {
  background: #fff;
}
.trackingmax {
  height: px(307);
  border-bottom: 1px solid #ebf0f6;
  display: flex;
}
.errmsg {
  height: px(307);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #999;
}
.tracking {
  width: px(560);
  padding: 0 14px;
}
.trackingmiss {
  width: px(7);
  background: #d8d8d8;
  opacity: 0.52;
  filter: blur(4px);
}
.materials {
  flex: 1;
  height: px(307);
}
.trackingNumber {
  height: px(58);
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ebf0f6;
}
.trackingNumberleft {
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #111111;
}
.companylogistics {
  display: flex;
  height: px(48);
  border-bottom: 1px solid #ebf0f6;
  justify-content: space-between;
}
.companylogisticsone {
  display: flex;
  align-items: center;
  color: #666666;
}
.companylogisticstwo {
  display: flex;
  align-items: center;
  color: #333333;
}
.el-step__iconis-text {
  width: 8px;
  height: 8px;
  background: #e5e5e5;
}
.el-timeline-item {
  padding-bottom: 0;
}
.el-timeline {
  margin-top: px(13);
  margin-left: px(82);
}

.layui-timeline {
  padding-left: px(230);
  padding-top: px(20);
  height: px(287);
  overflow-y: auto;
}

.layui-timeline-item {
  position: relative;
  padding-bottom: px(20);
  margin-top: px(-12);
  color: #999999;
  font-size: px(20);
  .time-first {
    position: absolute;
    left: px(-210);
    top: px(5);
  }
  p {
    color: #999999;
  }
}
.layui-timeline-item:first-child::before {
  display: block;
}
.layui-timeline-item:nth-child(1) {
  .time-first {
    font-size: px(20);
    font-weight: 500;
    color: #00afe9;
    display: inline-block;
  }
}
.layui-timeline-item:last-child::before {
  content: '';
  position: absolute;
  left: 5px;
  top: 0;
  z-index: 0;
  width: 0;
}

.layui-timeline-item::before {
  content: '';
  position: absolute;
  left: -1px;
  top: 0;
  z-index: 0;
  width: 1px;
  height: 100%;
}

.layui-timeline-item::before,
hr {
  background-color: #e6e6e6;
}

.layui-timeline-axis {
  position: absolute;
  left: -5px;
  top: 0;
  z-index: 10;
  width: 20px;
  height: 20px;
  line-height: 20px;
  background-color: #fff;
  color: #5fb878;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
}

.layui-icon {
  font-family: layui-icon !important;
  font-size: 16px;
  font-style: normal;
}

.layui-timeline-content {
  padding-left: 25px;
  h3 {
    margin: px(15) 0 px(10) 0;
    color: #999999;
    font-weight: normal;
  }

  .layui-timeline-title {
    position: relative;
    margin-bottom: px(10);
    font-weight: bold;
    color: #c1cdd1;
  }
  .layui-timeline-title:first-child {
    position: relative;
    margin-top: px(20);
    font-weight: bold;
    color: #00afe9;
  }
}

.layui-text {
  line-height: 22px;
  color: #666;
  top: -10px;
}
.circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #e5e5e5;
  font-style: normal;
  img {
    margin-left: -6px;
  }
}

#main {
  width: 100%;
  height: px(484);
  margin-top: px(20);
  background: #fff;
}
.descCla{
  width: 100%;
  height: px(484);
  margin-top: px(20);
  background: #fff;
  text-align: center;
  font-size: px(25);
  line-height: px(200);
}
</style>
