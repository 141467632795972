import request from '@/utils/request'
const apiDetail = {
  // 订单详情页面
  orderDetail(data) {
    return request({
      url: '/api/orders/order/',
      method: 'get',
      params: data
    })
  },
  // 产品试剂
  productDetail(data) {
    return request({
      url: '/api/products/product/',
      method: 'get',
      params: data
    })
  },

  // 仪器详情页面
  instrumentDetail(data) {
    return request({
      url: '/api/instruments/instrument/',
      method: 'get',
      params: data
    })
  },
  // 试剂详情页面
  kitDetail(data) {
    return request({
      url: '/api/kits/kit',
      method: 'get',
      params: data
    })
  },
  // 质检单详情页面
  reportDetail(data) {
    return request({
      url: '/api/reports/report/',
      method: 'get',
      params: data
    })
  },
  // 质控详情页面
  qcDetail(data) {
    return request({
      url: '/api/qcs/qc',
      method: 'get',
      params: data
    })
  },
  // // 定标详情页面
  // calibrationDetail(data) {
  //   return request({
  //     url: '/api/calibrations/calibration/',
  //     method: 'get',
  //     params: data
  //   })
  // },
  // 发货单详情页面
  dispatchDetail(data) {
    return request({
      url: '/api/dispatchs/dispatch/',
      method: 'get',
      params: data
    })
  },
  // 查日志详情
  diaryDetail(data) {
    return request({
      url: '/api/diarys/diary/',
      method: 'get',
      params: data
    })
  },
  // 查定标详情
  calibrationDetail(data) {
    return request({
      url: '/api/calibrations/calibration/',
      method: 'get',
      params: data
    })
  },
  // 查告警详情
  recordwarningDetail(data) {
    return request({
      url: '/api/recordwarnings/recordwarning/',
      method: 'get',
      params: data
    })
  },
  // 物品详情
  articleapplyDetail(data) {
    return request({
      url: '/api/articleapplis/articleapply/',
      method: 'get',
      params: data
    })
  },
  // 维修单详情
  repairtaskDetail(data) {
    return request({
      url: '/api/tasks/repairtask/',
      method: 'get',
      params: data
    })
  },
  // 保养单详情
  maintaintaskDetail(data) {
    return request({
      url: '/api/tasks/maintaintask/',
      method: 'get',
      params: data
    })
  },
  // 装机单详情
  installtaskDetail(data) {
    return request({
      url: '/api/tasks/installtask/',
      method: 'get',
      params: data
    })
  },
  // 样本检测项目
  samplecheckDetail(data) {
    return request({
      url: '/api/samplechecks/samplecheck/',
      method: 'get',
      params: data
    })
  },
  // // 查失控-失控详情 旧
  // qc_serum(data) {
  //   return request({
  //     url: '/api/qc_serum/',
  //     method: 'get',
  //     params: data
  //   })
  // },
  // 查失控-失控详情 新
  qc_point(data) {
    return request({
      url: '/api/qc_point/',
      method: 'get',
      params: data
    })
  },
  //修改物品申请状态-驳回/待发货
  article_apply_status(data) {
    return request({
      url: '/api/article_apply_status/',
      method: 'post',
      data
    })
  },
  //修改物品申请库存
  add_article_reserve(data) {
    return request({
      url: '/api/add_article_reserve/',
      method: 'post',
      data
    })
  },
  //文件打包压缩接口
  upload_package(data) {
    return request({
      url: '/api/upload_package/',
      method: 'post',
      data
    })
  },
  //仪器状态编辑
  edit_instrument(data) {
    return request({
      url: '/api/edit_instrument/',
      method: 'post',
      data
    })
  },
  //生成仪器解锁码
  get_unlock_code(data) {
    return request({
      url: '/api/instruments/unlock_code/',
      method: 'post',
      data
    })
  },
}
export default apiDetail
